import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
// import CanvasJS from '@canvasjs/charts';

// Register plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
// const CanvasJSChart = CanvasJSReact.CanvasJSChart;
function ActivityChart(props) {
    const { reportData } = props;

    const data = {
        labels: reportData?.ReportPercentage?.map((f) => f.description),
        datasets: [
            {
                label: 'Activity',
                data: reportData?.ReportPercentage?.map((f) => Math.ceil(f.percentage)),
                backgroundColor: [
                    '#DC3912',
                    '#3366CC',
                    '#FF9900',
                    '#109618',
                    '#990099',
                    '#0099C6',
                ],
                borderColor: [
                    '#DC3912',
                    '#3366CC',
                    '#FF9900',
                    '#109618',
                    '#990099',
                    '#0099C6',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                enabled: true, // Enables tooltips
            },
            legend: {
                display: false, // Hides the legend to avoid clutter
            },
            datalabels: {
                display: true, // Displays data labels
                color: '#fff', // Label text color
                font: {
                    size: 14, // Adjust font size for labels
                },
                // formatter: (value, context) => {
                //     // Customizes the label format
                //     const label = context.chart.data.labels[context.dataIndex];
                //     return `${label}: ${value}%`;
                // },
            },
        },
        responsive: true,
        maintainAspectRatio: false, // Ensures responsive resizing
    };

    return (
        <div style={{ width: '100%', maxWidth: '500px', height: '500px', margin: 'auto' }}>
            <Doughnut data={data} options={options} />
        </div>
    );
}

export default ActivityChart;
