import React from 'react';
import projectManagment from '../../../images/Pm.png';
import monitoring from '../../../images/Monitoring.png';
import TrainingAndAssesment from '../../../images/TandA.png';
import { useNavigate } from 'react-router-dom';

function ETIOP({ language }) {
    const navigate = useNavigate();
    return (
        <div
            style={{
                width: '100%',
                padding: '4rem 0',
                backgroundColor: '#FFFFFF',
                textAlign: 'center',
            }}
        >
            {/* Heading */}
            <h2
                style={{
                    fontFamily: "'Sinkin Sans', sans-serif",
                    fontSize: '44px',
                    fontWeight: '700',
                    color: '#3B3C4E',
                    marginBottom: '10px',
                }}
            >
                {language === 'en' ? 'Everything in ' : 'كل شيء في '}
                <span style={{ color: '#7ACB59' }}>
                    {language === 'en' ? 'One Place' : 'مكان واحد'}
                </span>
            </h2>
            <p
                style={{
                    fontFamily: "'Sinkin Sans', sans-serif",
                    fontSize: '18px',
                    fontWeight: '400',
                    color: '#212529',
                    marginBottom: '20px',
                    maxWidth: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                {language === 'en'
                    ? 'Experience the power of all-in-one management and monitoring with a free trial. Get started today and transform the way you work.'
                    : 'اكتشف قوة الإدارة والمراقبة المتكاملة مع نسخة تجريبية مجانية. ابدأ اليوم وحوّل طريقة عملك.'}
            </p>

            {/* Cards Section */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '2rem',
                    padding: '1rem',
                }}
            >
                {[{
                    img: projectManagment,
                    title: language === 'en' ? 'Project Management' : 'إدارة المشاريع',
                    text: language === 'en'
                        ? 'Streamline your workflow with cutting-edge tools that bring teams, tasks, and deadlines together seamlessly.'
                        : 'قم بتبسيط سير عملك باستخدام أدوات متطورة تجمع الفرق والمهام والمواعيد النهائية بسلاسة.',
                }, {
                    img: monitoring,
                    title: language === 'en' ? 'Monitoring' : 'المراقبة',
                    text: language === 'en'
                        ? 'Stay on top of your team\'s productivity and progress with real-time insights and performance metrics.'
                        : 'ابقَ على اطلاع على إنتاجية فريقك وتقدمه من خلال الرؤى في الوقت الفعلي.',
                }, {
                    img: TrainingAndAssesment,
                    title: language === 'en' ? 'Training and Assessment' : 'التدريب والتقييم',
                    text: language === 'en'
                        ? 'Empower your team with tailored training solutions and detailed performance assessments.'
                        : 'قم بتمكين فريقك من خلال حلول تدريب مخصصة وتقييمات أداء دقيقة.',
                }].map((card, index) => (
                    <div
                        key={index}
                        style={{
                            flex: '1',
                            minWidth: '300px',
                            maxWidth: '400px',
                            textAlign: 'center',
                            borderRadius: '10px',
                            padding: '2rem',
                            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                        }}
                    >
                        <div style={{ marginBottom: '10%', height: '50%' }}>
                            <img
                                src={card.img}
                                alt={card.title}
                                style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                }}
                            />
                        </div>
                        <div>
                            <h3
                                style={{
                                    fontSize: '1.8rem',
                                    fontWeight: '700',
                                    color: '#3B3C4E',
                                    marginBottom: '10px',
                                }}
                            >
                                {card.title}
                            </h3>
                            <p
                                style={{
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                    color: '#555555',
                                }}
                            >
                                {card.text}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Call-to-Action Button */}
            <div style={{ marginTop: '20px' }}>
                <button
                    style={{
                        padding: '1rem 2rem',
                        fontSize: '18px',
                        fontWeight: '400',
                        color: '#FFFFFF',
                        backgroundColor: '#7ACB59',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease-in-out',
                    }}
                    onClick={() => navigate("/signup")}
                >
                    {language === 'en' ? 'Start Free Trial →' : 'ابدأ النسخة التجريبية المجانية →'}
                </button>
            </div>

            {/* Responsive Styles */}
            <style>
                {`
                @media (max-width: 1024px) {
                    h2 {
                        font-size: 36px;
                    }
                    p {
                        font-size: 16px;
                    }
                }

                @media (max-width: 768px) {
                    h2 {
                        font-size: 32px;
                    }
                    p {
                        font-size: 14px;
                    }
                    div[style*="display: flex"] {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                @media (max-width: 480px) {
                    h2 {
                        font-size: 28px;
                    }
                    p {
                        font-size: 12px;
                    }
                    button {
                        width: 100%;
                        font-size: 16px;
                    }
                    img {
                        max-width: 100%;
                    }
                }
                `}
            </style>
        </div>
    );
}

export default ETIOP;
