import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apple from '../../images/apple.svg';
import chrome from '../../images/chrome.svg';
import laptopandmob from '../../images/laptopand mob.svg';
import microsoftlogo from '../../images/microsoft.svg';
import playstore from '../../images/playStore.svg';
import NavigationBar from '../../screen/component/header'; // Import your NavigationBar component
import NewHIW from '../LandingPage/Components/newHIW';
import ETIOP from '../LandingPage/Components/ETIOP'
import FeaturesSection from '../LandingPage/Components/FeaturesSection';
import ProductivitySection from '../LandingPage/Components/ProductivitySection';
import PricingSection from '../../screen/LandingPage/Components/PricingSection'
import DownaloadApp from '../LandingPage/Components/DownloadApp'
import FAQ from '../LandingPage/Components/FAQ';
import ContactSection from '../LandingPage/Components/ContactSection'
import StatsSection from '../LandingPage/Components/StatsSection';
import StartingSStrack from '../LandingPage/Components/StartingSStrack';
import translations from './Components/translations';
import NewHeader from '../component/Header/NewHeader';

function NewHome() {
  const navigate = useNavigate();
  const contactSectionRef = useRef(null); // Create a ref for ContactSection

  const scrollToContactSection = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [language, setLanguage] = useState('en');

  const handleToggleLanguage = () => {
    setLanguage(language === 'en' ? 'ar' : 'en');
  };

  const currentText = translations[language];
  return (
    <div style={{width:'100%'}}>
      {/* Navbar */}

      {/* Main content */}
      <div style={{
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: '#ffffff',
        scrollbarWidth: 'none',
        fontFamily: "'Sinkin Sans', sans-serif",
        textAlign: language === 'ar' ? 'right' : 'left',
      }}>
        <div style={{ position: 'sticky', top: '0', width: '100%', zIndex: '10' }}>
          <NewHeader language={language} handleToggleLanguage={handleToggleLanguage} />
        </div>

        <div style={{
          position: 'absolute',
          width: '150%',
          height: '190%',
          top: '-115%',
          background: 'linear-gradient(90deg, #0D4873, #0A304B, #071F2D, #0C364F, #0D4873)',
          borderRadius: '50%',
          zIndex: 1,
        }}></div>

        <div className="container" style={{
          position: 'relative',
          zIndex: 2,
          textAlign: 'center',
          color: '#ffffff',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '90%',
          maxWidth: '1200px',
        }}>
          <h1 style={{
            fontSize: '2.2rem',
            fontWeight: '700',
            marginBottom: '30px',
            marginTop: '10%',
          }}>{currentText.heading}</h1>

          <p style={{
            fontSize: '1rem',
            fontWeight: '400',
            marginBottom: '50px',
            textAlign: 'center',
            width: '100%',
            maxWidth: '800px',
          }}>
            {currentText.description}
          </p>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0',
            marginBottom: '2rem',
            width: '90%',
            maxWidth: '500px',
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}>
            <input
              type="email"
              placeholder={currentText.signUpPlaceholder}
              className="form-control"
              style={{
                flex: '2',
                padding: '0.86rem 1rem',
                fontSize: '0.9rem',
                border: 'none',
                outline: 'none',
                borderTopRightRadius:'0px',
                borderBottomRightRadius:'0px'
              }}
            />
            <button style={{
              padding: '0.9rem 1.5rem',
              fontSize: '0.9rem',
              border: 'none',
              backgroundColor: '#7ACB59',
              color: '#ffffff',
              cursor: 'pointer',
              fontWeight: 'bold',
            }} onClick={() => navigate("/signup")}>{currentText.signUpButton}</button>
          </div>

          <p style={{
            fontSize: '1rem',
            fontWeight: '400',
            marginBottom: '2.49rem',
            marginTop: '1rem',
          }}>
            {currentText.availablePlatforms}
          </p>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            flexWrap: 'wrap',
          }}>
            <img src={microsoftlogo} alt="Microsoft" style={{ width: '10rem' }} />
            <img src={apple} alt="Apple" style={{ width: '3rem' }} />
            <img src={playstore} alt="Google Play" style={{ width: '10rem' }} />
            <img src={chrome} alt="Chrome Web Store" style={{ width: '10rem' }} />
          </div>
        </div>

        <img
          src={laptopandmob}
          alt="Laptop and Mobile Mockup"
          style={{
            position: 'relative',
            width: '90%',
            maxWidth: '800px',
            zIndex: 2,
            margin: '20px 0',
          }}
        />

        <style>
          {`
      @media (max-width: 1024px) {
        h1 {
          font-size: 1.8rem;
        }
        p {
          font-size: 0.9rem;
        }
        button {
          padding: 0.9rem 1rem;
        }
      }
      
      @media (max-width: 768px) {
        h1 {
          font-size: 1.6rem;
        }
        p {
          font-size: 0.8rem;
          width: 100%;
        }
        .form-control {
          font-size: 0.8rem;
        }
      }

      @media (max-width: 480px) {
        h1 {
          font-size: 1.4rem;
        }
        p {
          font-size: 0.75rem;
          width: 100%;
        }
        button {
          padding: 0.8rem 0.8rem;
        }
        img {
          width: 8rem;
        }
      }
    `}
        </style>
      </div>

      <StatsSection language={language} />
      <div id="section1">
        <NewHIW language={language} />
      </div>
      <ETIOP language={language} />
      <FeaturesSection language={language} />
      <ProductivitySection language={language} />
      <div id="pricing">
        <PricingSection onContactButtonClick={scrollToContactSection} language={language} />
      </div>
      <DownaloadApp language={language} />
      <div id="faq">
        <FAQ onContactButtonClick={scrollToContactSection} language={language} />
      </div>
      <div ref={contactSectionRef} id="section3">
        <ContactSection language={language} />
      </div>
      <StartingSStrack language={language} id="last" />
    </div>
  );
}


export default NewHome;

