import React from 'react'
import step1 from '../../../images/step1.png'
import step2 from '../../../images/step2.png'
import step3 from '../../../images/step3.png'
import step4 from '../../../images/step4.png'
import 'bootstrap/dist/css/bootstrap.min.css';

function NewHIW({ language }) {
    return (
        <div style={{
            width: '100%',
            padding: '2rem 0',
            backgroundColor: '#F9F9F9',
            textAlign: 'center'
        }}>
            {/* Heading */}
            <h2 style={{
                fontFamily: "'Sinkin Sans', sans-serif",
                fontSize: '44px',
                fontWeight: '700',
                color: '#3B3C4E',
                marginBottom: '10px'
            }}>
                {language === 'en' ? 'How It' : 'كيف'}
                <span style={{ color: '#7ACB59', fontSize: '44px', fontWeight: '700' }}>
                    {language === 'en' ? ' Works' : ' يعمل'}
                </span>
            </h2>
            <p style={{
                fontSize: '16px',
                fontWeight: '400',
                color: '#212529',
                marginBottom: '30px'
            }}>
                {language === 'en'
                    ? 'A Smarter Way to Track and Manage.'
                    : 'طريقة أكثر ذكاءً لتتبع وإدارة العمل.'}
            </p>

            {/* Steps */}
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '30px',
                    width: '80%',
                    margin: '0 auto',
                }}
            >
                <div style={{ flex: '1', minWidth: '300px', marginTop: '10%' }}>
                    {[step1, step3].map((step, index) => (
                        <div key={index} className={`step-left-${index + 1}`} style={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '10px',
                            boxShadow: `
                                0px 30px 45px -30px rgba(50, 50, 93, 0.2),
                                0px 18px 36px -18px rgba(0, 0, 0, 0.1)
                            `,
                            maxWidth: '500px',
                            textAlign: language === 'ar' ? 'right' : 'left',
                            paddingBottom: '20px',
                            marginBottom: '30px',
                        }}>
                            <div style={{ padding: '6px' }}>
                                <img src={step} alt={`Step ${index + 1}`} style={{ width: '100%', borderRadius: '10px' }} />
                            </div>
                            <div style={{ padding: '20px 30px' }}>
                                <p style={{ fontSize: '44px', fontWeight: '700', color: '#7ACB59' }}>
                                    0{index * 2 + 1}
                                </p>
                                <p style={{ fontSize: '23px', fontWeight: '600', color: '#0D4873' }}>
                                    {language === 'en' ? ['Sign Up', 'Analyze & Optimize'][index] : ['اشترك', 'التحليل والتحسين'][index]}
                                </p>
                                <p style={{ fontSize: '16px', fontWeight: '400', color: '#555555' }}>
                                    {language === 'en'
                                        ? ['Get started in minutes—email verification is all it takes.', 'Use advanced analytics and insights to improve productivity.'][index]
                                        : ['ابدأ في دقائق - كل ما تحتاجه هو التحقق من البريد الإلكتروني.', 'استخدم التحليلات المتقدمة والرؤى لتحسين الإنتاجية.'][index]
                                    }
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{ flex: '1', minWidth: '300px' }}>
                    {[step2, step4].map((step, index) => (
                        <div key={index} style={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '10px',
                            boxShadow: `
                                0px 30px 45px -30px rgba(50, 50, 93, 0.2),
                                0px 18px 36px -18px rgba(0, 0, 0, 0.1)
                            `,
                            maxWidth: '500px',
                            textAlign: language === 'ar' ? 'right' : 'left',
                            paddingBottom: '20px',
                            marginBottom: '30px',
                        }}>
                            <div style={{ padding: '6px' }}>
                                <img src={step} alt={`Step ${index + 2}`} style={{ width: '100%', borderRadius: '10px' }} />
                            </div>
                            <div style={{ padding: '20px 30px' }}>
                                <p style={{ fontSize: '44px', fontWeight: '700', color: '#7ACB59' }}>
                                    0{index * 2 + 2}
                                </p>
                                <p style={{ fontSize: '23px', fontWeight: '600', color: '#0D4873' }}>
                                    {language === 'en' ? ['Precision Tools', 'Get the Tools'][index] : ['أدوات دقيقة', 'احصل على الأدوات'][index]}
                                </p>
                                <p style={{ fontSize: '16px', fontWeight: '400', color: '#555555' }}>
                                    {language === 'en'
                                        ? ['Real-time tracking, flawless logins, and screenshot control.', 'Download the app or extension to track work hours.'][index]
                                        : ['التتبع في الوقت الفعلي، تسجيل الدخول السلس، والتحكم في لقطات الشاشة.', 'قم بتنزيل التطبيق أو الإضافة لتتبع ساعات العمل.'][index]
                                    }
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Responsive Styles */}
            <style>
                {`
    @media (max-width: 768px) {
        div[style*="display: flex"] {
            flex-direction: column;
            align-items: center;
        }
        .step-left-1, .step-left-2 {
            margin-top: 0;
        }
    }

    @media (max-width: 480px) {
        h2 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
        }
        img {
            max-width: 100%;
        }
        div[style*="display: flex"] {
            flex-direction: column;
            align-items: center;
            justifyContent: 'center',
            
        }
    .step-left-1, .step-left-2 {
            margin-top: 0;
        }
        .step-container {
            width: 90%;
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
        }
    }
    `}
            </style>

        </div>
    )
}

export default NewHIW;
